
html, body{
  background-color: #303030;
  color: white;
  min-height: 100vh;
}

.main{
  min-height: 80vh;
  margin-bottom: 100px;
}

// custom menu
.custom-menu{
  margin-bottom: 20px;
  border-bottom: 1px solid #000000;
  background: #202020 !important;
}

.custom-menu-item{
  color: white !important;
  margin-right: 20px;
}

.menu-title, .menu-title:hover{
  background: #202020 !important;
  color: #099 !important;
  font-weight: 500;
  margin-right: 20px;
}

.menu-dropdown{
  position: relative;
  margin-right: 30px !important;
}

.menu-dropdown-title{

}

.menu-dropdown-content{
  display: none;
  position: absolute;
  padding-top: 46px;
  top: 0px;
  flex-direction: column;
  z-index: 10;

  .menu-item{
    white-space: nowrap;
    color: white;
    padding: 15px 25px;
    background: #202020;
    border: 1px solid #303030;
  }

  .menu-item:hover{
    background: #262626;
  }

}

.menu-dropdown:hover .menu-dropdown-content, .menu-dropdown-content:hover{
  display: flex;
}

.logo{
  margin: 10px 0;
}


// connect button
.connectButton, .connectButton:hover, .connectButton:focus{
  font-weight: 700;
  font-size: 18px;
  // color: #777;
}

.connectButton.green, .connectButton:hover.green, .connectButton:focus.green{
  color: #0b0;
  border: 1px solid #0b0;
}



.hr{
  border-top: 1px solid #ccc;
}

.red{
  color: #f00 !important;
}
.green{
  color: #0b0 !important;
}
.blue{
  color: #88f;
}
.light-grey{
  color: #777 !important;
}
.light-blue{
  color: #d0d0ff;
}
.light-green{
  color: #a0ffa0;
}
.light-red{
  color: #ffb0b0;
}

.address-input{
  width: 400px;
}
.number-input{
  width: 140px;
}

// warning 
.warning li{
  font-size: 18px;
}

.warning li:not(:last-child){
  margin-bottom: 4px;
}

.warning{
  border: 1px solid #cc0f35;
}

.bordered-white{
  border: 1px solid #777;
  padding: 4px 10px 10px 10px;
  margin-bottom: 20px;
}


// pool list

.pool{
  background-color: #202020;
  padding: 20px;
  border-radius: 20px;

  color: white;
  font-weight: 500;

  animation: fade-in 1s linear;
}

.pool-index{
  color: #ccc;
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 20px;
}


@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}


.pool-token-name{
  // display: inline-block;
  // min-width: 200px;
  padding-right: 20px;
}

.pool-token-amount{
  // display: inline-block;
  // min-width: 60px;
  padding-right: 20px;
  color: #d0d0ff;
}

.loading-button{
  background: rgba(0, 0, 0, 0);
  border: none;
}

// factory-list
.factory-list{
  .factory-list-name{
    display: inline-block;
    min-width: 140px;
    text-align: right;
    margin-right: 10px;
  }

  .factory-list-amount{

  }
}

.impermanent{
  .factory-list-name{
    min-width: 300px;
    text-align: right;
  }
}

.slider{
  width: 400px;
}

// help

.help-toggle{
  font-size: 14px;
}

.help-content{

}

// dark table
.dark-table th{
  color: #cfc !important;
  background: #333;
  border: 1px solid #355;
} 

.dark-table, .dark-table td{
  color: white;
  background: #333;
  border: 1px solid #355;
}

// docs
.docs{
  a {
   color: #b0b0ff;
  }
  a:hover {
   color: #c0c0ff;
  }
}

// footer
.footer{
  background: #202020;
  padding-top: 30px;
  padding-bottom: 30px;
}

.bell{
  margin-top: 22px;
}

// mobile
@media only screen and (max-width: 1023px) {

  .container{
    margin: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .pool-display-table{
    td{
      border: 1px solid #555;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 574px) {
  .bell{
    margin-top: 10px;
  }
}


